import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { $getNodeByKey, CLICK_COMMAND, COMMAND_PRIORITY_LOW } from 'lexical';
import { useApplication } from '@/Components/ApplicationProvider';
import FilePreview from '@/Components/FilePreview/FilePreview';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
export function FileComponent({ className, format, nodeKey, fileUuid, zoomLevel, setZoomLevel }) {
    const application = useApplication();
    const [editor] = useLexicalComposerContext();
    const file = useMemo(() => application.items.findItem(fileUuid), [application, fileUuid]);
    const [canLoad, setCanLoad] = useState(false);
    const blockWrapperRef = useRef(null);
    const blockObserver = useMemo(() => new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setCanLoad(true);
            }
        });
    }, {
        threshold: 0.25,
    }), []);
    useEffect(() => {
        const wrapper = blockWrapperRef.current;
        if (!wrapper) {
            return;
        }
        blockObserver.observe(wrapper);
        return () => {
            blockObserver.unobserve(wrapper);
        };
    }, [blockObserver]);
    const setImageZoomLevel = useCallback((zoomLevel) => {
        editor.update(() => {
            setZoomLevel(zoomLevel);
        });
    }, [editor, setZoomLevel]);
    const [isSelected, setSelected] = useLexicalNodeSelection(nodeKey);
    useEffect(() => {
        return editor.registerCommand(CLICK_COMMAND, (event) => {
            var _a, _b;
            if ((_a = blockWrapperRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)) {
                event.preventDefault();
                (_b = $getNodeByKey(nodeKey)) === null || _b === void 0 ? void 0 : _b.selectEnd();
                setTimeout(() => {
                    setSelected(!isSelected);
                });
                return true;
            }
            return false;
        }, COMMAND_PRIORITY_LOW);
    }, [editor, isSelected, nodeKey, setSelected]);
    if (!file) {
        return (_jsx(BlockWithAlignableContents, { className: className, format: format, nodeKey: nodeKey, children: _jsxs("div", { children: ["Unable to find file ", fileUuid] }) }));
    }
    return (_jsx(BlockWithAlignableContents, { className: className, format: format, nodeKey: nodeKey, children: _jsx("div", { ref: blockWrapperRef, children: canLoad && (_jsx(FilePreview, { isEmbeddedInSuper: true, file: file, application: application, imageZoomLevel: zoomLevel, setImageZoomLevel: setImageZoomLevel })) }) }));
}
