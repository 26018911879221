import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Icon from '@/Components/Icon/Icon';
import { getPositionedPopoverStyles } from '@/Components/Popover/GetPositionedPopoverStyles';
import { getAdjustedStylesForNonPortalPopover } from '@/Components/Popover/Utils/getAdjustedStylesForNonPortal';
import StyledTooltip from '@/Components/StyledTooltip/StyledTooltip';
import { useElementResize } from '@/Hooks/useElementRect';
import { $isAutoLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import { classNames } from '@standardnotes/snjs';
import { COMMAND_PRIORITY_LOW, SELECTION_CHANGE_COMMAND } from 'lexical';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { getDOMRangeRect } from '../../Lexical/Utils/getDOMRangeRect';
import { ElementIds } from '@/Constants/ElementIDs';
import { createPortal } from 'react-dom';
const LinkViewer = ({ isMobile, editor, linkNode, setIsEditingLink }) => {
    var _a;
    const linkViewerRef = useRef(null);
    const [linkUrl, isAutoLink] = useMemo(() => {
        let linkUrl = '';
        let isAutoLink = false;
        editor.getEditorState().read(() => {
            linkUrl = linkNode.getURL();
            isAutoLink = $isAutoLinkNode(linkNode);
        });
        return [linkUrl, isAutoLink];
    }, [editor, linkNode]);
    const rangeRect = useRef();
    const updateLinkEditorPosition = useCallback(() => {
        if (isMobile) {
            return;
        }
        const nativeSelection = window.getSelection();
        const rootElement = editor.getRootElement();
        if (nativeSelection !== null && rootElement !== null) {
            if (rootElement.contains(nativeSelection.anchorNode)) {
                rangeRect.current = getDOMRangeRect(nativeSelection, rootElement);
            }
        }
        const linkEditorElement = linkViewerRef.current;
        if (!linkEditorElement) {
            setTimeout(updateLinkEditorPosition);
            return;
        }
        if (!rootElement) {
            return;
        }
        const linkEditorRect = linkEditorElement.getBoundingClientRect();
        const rootElementRect = rootElement.getBoundingClientRect();
        const calculatedStyles = getPositionedPopoverStyles({
            align: 'center',
            side: 'top',
            anchorRect: rangeRect.current,
            popoverRect: linkEditorRect,
            documentRect: rootElementRect,
            offset: 12,
            maxHeightFunction: () => 'none',
        });
        if (calculatedStyles) {
            const adjustedStyles = getAdjustedStylesForNonPortalPopover(linkEditorElement, calculatedStyles);
            Object.entries(adjustedStyles).forEach(([key, value]) => {
                linkEditorElement.style.setProperty(key, value);
            });
            linkEditorElement.style.opacity = '1';
        }
    }, [editor, isMobile]);
    useElementResize(linkViewerRef.current, updateLinkEditorPosition);
    useEffect(() => {
        updateLinkEditorPosition();
        return mergeRegister(editor.registerUpdateListener(() => {
            updateLinkEditorPosition();
        }), editor.registerCommand(SELECTION_CHANGE_COMMAND, (_payload) => {
            updateLinkEditorPosition();
            return false;
        }, COMMAND_PRIORITY_LOW));
    }, [editor, updateLinkEditorPosition]);
    if (!linkUrl) {
        return null;
    }
    return createPortal(_jsx("div", { className: classNames('absolute z-dropdown-menu rounded-lg border border-border bg-contrast px-2 py-1 shadow-sm shadow-contrast', isMobile
            ? 'bottom-12 left-1/2 w-[calc(100%_-_1rem)] -translate-x-1/2'
            : 'left-0 top-0 w-auto translate-x-0 opacity-0 translucent-ui:border-[--popover-border-color] translucent-ui:bg-[--popover-background-color] translucent-ui:[backdrop-filter:var(--popover-backdrop-filter)]'), ref: linkViewerRef, children: _jsxs("div", { className: "flex items-center gap-1", children: [_jsxs("a", { className: classNames('mr-1 flex flex-grow items-center gap-2 overflow-hidden whitespace-nowrap underline', isAutoLink && 'py-2.5'), href: linkUrl, target: "_blank", rel: "noopener noreferrer", children: [_jsx(Icon, { type: "open-in", className: "ml-1 flex-shrink-0" }), _jsx("div", { className: "max-w-[35ch] overflow-hidden text-ellipsis", children: linkUrl })] }), _jsx(StyledTooltip, { showOnMobile: true, showOnHover: true, label: "Copy link", children: _jsx("button", { className: "flex select-none items-center justify-center rounded p-2 enabled:hover:bg-default disabled:opacity-50 md:border md:border-transparent enabled:hover:md:translucent-ui:border-[--popover-border-color]", onClick: () => {
                            navigator.clipboard.writeText(linkUrl).catch(console.error);
                        }, onMouseDown: (event) => event.preventDefault(), children: _jsx(Icon, { type: "copy", size: "medium" }) }) }), !isAutoLink && (_jsxs(_Fragment, { children: [_jsx(StyledTooltip, { showOnMobile: true, showOnHover: true, label: "Edit link", children: _jsx("button", { className: "flex select-none items-center justify-center rounded p-2 enabled:hover:bg-default disabled:opacity-50 md:border md:border-transparent enabled:hover:md:translucent-ui:border-[--popover-border-color]", onClick: () => {
                                    setIsEditingLink(true);
                                }, onMouseDown: (event) => event.preventDefault(), children: _jsx(Icon, { type: "pencil-filled", size: "medium" }) }) }), _jsx(StyledTooltip, { showOnMobile: true, showOnHover: true, label: "Remove link", children: _jsx("button", { className: "flex select-none items-center justify-center rounded p-2 enabled:hover:bg-default disabled:opacity-50 md:border md:border-transparent enabled:hover:md:translucent-ui:border-[--popover-border-color]", onClick: () => {
                                    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
                                }, onMouseDown: (event) => event.preventDefault(), children: _jsx(Icon, { type: "trash-filled", size: "medium" }) }) })] }))] }) }), (_a = document.getElementById(ElementIds.SuperEditor)) !== null && _a !== void 0 ? _a : document.body);
};
export default LinkViewer;
