import { jsx as _jsx } from "react/jsx-runtime";
import { DecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import { $createFileNode, convertToFileElement } from './FileUtils';
import { FileComponent } from './FileComponent';
export class FileNode extends DecoratorBlockNode {
    static getType() {
        return 'snfile';
    }
    static clone(node) {
        return new FileNode(node.__id, node.__format, node.__key, node.__zoomLevel);
    }
    static importJSON(serializedNode) {
        const node = $createFileNode(serializedNode.fileUuid);
        node.setFormat(serializedNode.format);
        node.setZoomLevel(serializedNode.zoomLevel);
        return node;
    }
    exportJSON() {
        return {
            ...super.exportJSON(),
            fileUuid: this.getId(),
            version: 1,
            type: 'snfile',
            zoomLevel: this.__zoomLevel,
        };
    }
    static importDOM() {
        return {
            div: (domNode) => {
                if (!domNode.hasAttribute('data-lexical-file-uuid')) {
                    return null;
                }
                return {
                    conversion: convertToFileElement,
                    priority: 2,
                };
            },
        };
    }
    exportDOM() {
        const element = document.createElement('span');
        element.setAttribute('data-lexical-file-uuid', this.__id);
        const text = document.createTextNode(this.getTextContent());
        element.append(text);
        return { element };
    }
    constructor(id, format, key, zoomLevel) {
        super(format, key);
        this.__id = id;
        this.__zoomLevel = zoomLevel || 100;
    }
    getId() {
        return this.__id;
    }
    getTextContent(_includeInert, _includeDirectionless) {
        return `[File: ${this.__id}]`;
    }
    setZoomLevel(zoomLevel) {
        const writable = this.getWritable();
        writable.__zoomLevel = zoomLevel;
    }
    decorate(_editor, config) {
        const embedBlockTheme = config.theme.embedBlock || {};
        const className = {
            base: embedBlockTheme.base || '',
            focus: embedBlockTheme.focus || '',
        };
        return (_jsx(FileComponent, { className: className, format: this.__format, nodeKey: this.getKey(), fileUuid: this.__id, zoomLevel: this.__zoomLevel, setZoomLevel: this.setZoomLevel.bind(this) }));
    }
}
