import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getBase64FromBlob } from '@/Utils';
import { classNames } from '@standardnotes/snjs';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import Button from '../Button/Button';
import { createObjectURLWithRef } from './CreateObjectURLWithRef';
import ImagePreview from './ImagePreview';
import { PreviewableTextFileTypes, RequiresNativeFilePreview } from './isFilePreviewable';
import TextPreview from './TextPreview';
import { parseFileName } from '@standardnotes/filepicker';
import { sanitizeFileName } from '@standardnotes/ui-services';
import VideoPreview from './VideoPreview';
const PreviewComponent = ({ application, file, bytes, isEmbeddedInSuper, imageZoomLevel, setImageZoomLevel, }) => {
    const objectUrlRef = useRef();
    const objectUrl = useMemo(() => {
        return createObjectURLWithRef(file.mimeType, bytes, objectUrlRef);
    }, [bytes, file.mimeType]);
    useEffect(() => {
        const objectUrl = objectUrlRef.current;
        return () => {
            if (objectUrl) {
                URL.revokeObjectURL(objectUrl);
                objectUrlRef.current = '';
            }
        };
    }, []);
    const isNativeMobileWeb = application.isNativeMobileWeb();
    const requiresNativePreview = RequiresNativeFilePreview.includes(file.mimeType);
    const openNativeFilePreview = useCallback(async () => {
        if (!isNativeMobileWeb) {
            throw new Error('Native file preview cannot be used on non-native platform');
        }
        const fileBase64 = await getBase64FromBlob(new Blob([bytes], {
            type: file.mimeType,
        }));
        const { name, ext } = parseFileName(file.name);
        const sanitizedName = sanitizeFileName(name);
        const filename = `${sanitizedName}.${ext}`;
        void application.mobileDevice.previewFile(fileBase64, filename);
    }, [application, bytes, file.mimeType, file.name, isNativeMobileWeb]);
    if (isNativeMobileWeb && requiresNativePreview) {
        return (_jsxs("div", { className: "flex flex-grow flex-col items-center justify-center", children: [_jsx("div", { className: "max-w-[30ch] text-center text-base font-bold", children: "This file can only be previewed in an external app" }), _jsx(Button, { className: "mt-3", primary: true, onClick: openNativeFilePreview, children: "Open file preview" })] }));
    }
    if (file.mimeType.startsWith('image/')) {
        return (_jsx(ImagePreview, { objectUrl: objectUrl, isEmbeddedInSuper: isEmbeddedInSuper, imageZoomLevel: imageZoomLevel, setImageZoomLevel: setImageZoomLevel }));
    }
    if (file.mimeType.startsWith('video/')) {
        return (_jsx(VideoPreview, { file: file, filesController: application.filesController, objectUrl: objectUrl, isEmbeddedInSuper: isEmbeddedInSuper }));
    }
    if (file.mimeType.startsWith('audio/')) {
        return (_jsx("div", { className: "flex h-full w-full items-center justify-center", children: _jsx("audio", { src: objectUrl, controls: true }) }));
    }
    if (PreviewableTextFileTypes.includes(file.mimeType)) {
        return _jsx(TextPreview, { bytes: bytes });
    }
    const isPDF = file.mimeType === 'application/pdf';
    return (_jsx("object", { className: classNames('h-full w-full', isPDF && 'min-h-[65vh]'), data: isPDF ? objectUrl + '#view=FitV' : objectUrl }));
};
export default PreviewComponent;
