import { parseFileName } from '@standardnotes/filepicker';
export class PlaintextConverter {
    constructor() {
        this.convert = async (file, { insertNote, convertMarkdownToSuper, readFileAsText }) => {
            const content = await readFileAsText(file);
            const { name } = parseFileName(file.name);
            const createdAtDate = file.lastModified ? new Date(file.lastModified) : new Date();
            const updatedAtDate = file.lastModified ? new Date(file.lastModified) : new Date();
            const note = await insertNote({
                createdAt: createdAtDate,
                updatedAt: updatedAtDate,
                title: name,
                text: convertMarkdownToSuper(content),
                useSuperIfPossible: true,
            });
            return {
                successful: [note],
                errored: [],
            };
        };
    }
    getImportType() {
        return 'plaintext';
    }
    getSupportedFileTypes() {
        return ['text/plain', 'text/markdown'];
    }
    isContentValid(_content) {
        return true;
    }
    static isValidPlaintextFile(file) {
        return file.type === 'text/plain' || file.type === 'text/markdown';
    }
}
