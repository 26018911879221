import { parseFileName } from '@standardnotes/filepicker';
export class HTMLConverter {
    constructor() {
        this.convert = async (file, { insertNote: createNote, convertHTMLToSuper, readFileAsText }) => {
            const content = await readFileAsText(file);
            const { name } = parseFileName(file.name);
            const createdAtDate = file.lastModified ? new Date(file.lastModified) : new Date();
            const updatedAtDate = file.lastModified ? new Date(file.lastModified) : new Date();
            const text = convertHTMLToSuper(content);
            const note = await createNote({
                createdAt: createdAtDate,
                updatedAt: updatedAtDate,
                title: name,
                text,
                useSuperIfPossible: true,
            });
            return {
                successful: [note],
                errored: [],
            };
        };
    }
    getImportType() {
        return 'html';
    }
    getSupportedFileTypes() {
        return ['text/html'];
    }
    isContentValid(_content) {
        return true;
    }
}
