export async function readFile(file) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    return new Promise((resolve) => {
        reader.onload = (readerEvent) => {
            const target = readerEvent.target;
            const content = target.result;
            resolve(new Uint8Array(content));
        };
    });
}
export function parseFileName(fileName) {
    const pattern = /(?:\.([^.]+))?$/;
    const extMatches = pattern.exec(fileName);
    const ext = (extMatches === null || extMatches === void 0 ? void 0 : extMatches[1]) || '';
    const name = fileName.includes('.') ? fileName.substring(0, fileName.lastIndexOf('.')) : fileName;
    return { name, ext };
}
export function saveFile(name, bytes) {
    const link = document.createElement('a');
    const blob = new Blob([bytes], {
        type: 'text/plain;charset=utf-8',
    });
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(link.href);
}
const BYTES_IN_ONE_KILOBYTE = 1024;
const BYTES_IN_ONE_MEGABYTE = 1048576;
const BYTES_IN_ONE_GIGABYTE = 1073741824;
export function formatSizeToReadableString(bytes) {
    let size = bytes;
    let unit = 'B';
    if (bytes >= BYTES_IN_ONE_GIGABYTE) {
        size = bytes / BYTES_IN_ONE_GIGABYTE;
        unit = 'GB';
    }
    else if (bytes >= BYTES_IN_ONE_MEGABYTE) {
        size = bytes / BYTES_IN_ONE_MEGABYTE;
        unit = 'MB';
    }
    else if (bytes >= BYTES_IN_ONE_KILOBYTE) {
        size = bytes / BYTES_IN_ONE_KILOBYTE;
        unit = 'KB';
    }
    return `${Number.isInteger(size) ? size : size.toFixed(2)} ${unit}`;
}
